<template>
  <div :class="containerClass">
    <b-row>
      <b-col cols="12">
        <div class="settings-title mb-3 mt-3">
          {{ $t('components.form.subscriptions.new.access-control') }}
        </div>
        <template v-for="(access, index) of accesses">
          <access-control-form
            :key="index"
          />
        </template>
        <AddAccessRule
            class="mt-3"
            text-link="components.form.subscriptions.new.add-new-rule"
            @on:add-link-click="accesses.push(nbAccesses++)"
        />
      </b-col>
    </b-row>
  </div>
</template>
<script>
export default {
  components: {
    AccessControlForm: () => import('@form/subscriptions/new/subscription-access-control/AccessControlForm'),
    AddAccessRule: () => import('@custom/subscrtiptions/new/AddItemLink')
  },
  data: () => ({
    nbAccesses: 1,
    accesses: [1],
  }),
  props: {
    containerClass: {
      type: String,
      default: ''
    }
  }
}
</script>
<style scoped>

</style>
